import React, { useEffect, useState } from "react"
import "./index.scss"
import SEO from "@components/SEO"
import {
  SanityWhoWeServe,
  NavigationType,
  SanityHeroWithBlocks,
} from "@utils/globalTypes"
import LateralNavigation from "@components/LateralNavigation"
import useResizeWidth from "@hooks/useResizeWidth"
import { graphql } from "gatsby"
import { ContentTypes, SanityImageWithLeafs } from "@utils/globalTypes"
import SectionImageWithLeafs from "@components/SectionImageWithLeafs"
import ScrollUpButton from "@components/ScrollUpButton"
import { Row } from "react-bootstrap"
import HeroWithBlocks from "@components/hero/HeroWithBlocks"

type Props = {
  data: {
    sanityWhoWeServe: SanityWhoWeServe
    sanityNavigation: NavigationType
  }
  location: Location
}

const WhoWeServePage = ({
  data: { sanityWhoWeServe, sanityNavigation },
  location,
}: Props) => {
  const { backgroundColor, content } = sanityWhoWeServe
  const [latNavStyle, setLatNavStyle] = useState("wws-lateral-navigation-mt")
  const links = sanityNavigation?.content?.find(
    (item) => item?.slug?.current === `/${location.pathname.split("/")[1]}`
  )
  const { width } = useResizeWidth()
  const metadata = {
    location: location.href,
    ...sanityWhoWeServe?.seo,
  }

  useEffect(() => {
    if (window !== "undefined") {
      window.addEventListener("scroll", () => {
        if (window?.pageYOffset < 100) {
          setLatNavStyle("wws-lateral-navigation-mt")
        } else {
          setLatNavStyle("wws-lateral-navigation-sticky")
        }
      })
    }
  }, [])

  return (
    <div
      className={`wws ${latNavStyle} parent-padding`}
      style={{
        backgroundColor:
          content[0]?.backgroundColor || backgroundColor || "transparent",
      }}
    >
      <SEO {...metadata} />
      {width < 960 ? (
        <div className="lateral-mobile-nav">
          <LateralNavigation links={links} slug={sanityWhoWeServe.slug} />
        </div>
      ) : (
        <LateralNavigation links={links} slug={sanityWhoWeServe.slug} />
      )}

      <section className="ww-section-wrapper">
        {content &&
          content.map((section, index) => {
            switch (section._type) {
              case ContentTypes.SanityHeroWithBlocks:
                const sanityHeroWithBlocks = section as SanityHeroWithBlocks
                return (
                  <HeroWithBlocks
                    key={index}
                    sanityHeroWithBlocks={sanityHeroWithBlocks}
                    classes={`wwa-container wwa-padding-left wws-hero-grids-blocks ${
                      sanityHeroWithBlocks?.classes?.includes(
                        "section-with-image-bottom"
                      )
                        ? ""
                        : "wwa-container-padding-bottom"
                    }`}
                  />
                )
              case ContentTypes.SanityImageWithLeafs:
                const sanityImageWithLeafs = section as SanityImageWithLeafs
                return (
                  <SectionImageWithLeafs
                    key={index}
                    sanityImageWithLeafs={sanityImageWithLeafs}
                    classes={`wws-text-blocks wwa-padding-left wwa-padding-rigth ${
                      index > 0
                        ? "wwa-container"
                        : "wwa-first-section content-padding-top"
                    } ${
                      sanityImageWithLeafs?.classes?.includes("navy-text-color")
                        ? ""
                        : "wws-default-colors"
                    } ${
                      sanityImageWithLeafs?.classes?.includes(
                        "section-with-image-bottom"
                      )
                        ? ""
                        : "wwa-container-padding-bottom"
                    }`}
                    blockClass="siwl-text-content-wws"
                  />
                )
              default:
                break
            }
          })}
        {width > 640 ? (
          <ScrollUpButton type="ligth" />
        ) : (
          <Row
            className="hwh-backTop-container"
            style={{
              backgroundColor:
                content[content.length - 1]?.backgroundColor || `transparent`,
            }}
          >
            {" "}
            <ScrollUpButton
              type="ligth"
              style={{
                position: "relative",
                right: "initial",
                bottom: "initial",
                opacity: 1,
              }}
            />{" "}
          </Row>
        )}
      </section>
    </div>
  )
}

export default WhoWeServePage

export const query = graphql`
  query sanityWhoWeServe($slug: String) {
    sanityWhoWeServe(slug: { current: { eq: $slug } }) {
      seo {
        description
        keywords
        image
        title
        twitterCard
        twitterCreator
        twitterSite
      }
      title
      slug {
        current
      }
      backgroundColor
      content {
        ... on SanityHeroWithBlocks {
          name
          _type
          backgroundColor
          backgroundImage {
            alt
            asset {
              fluid(maxWidth: 10000) {
                ...GatsbySanityImageFluid
              }
            }
          }
          titleWithImage {
            _rawTitle(resolveReferences: { maxDepth: 10 })
            image {
              alt
              asset {
                fluid(maxWidth: 10000) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          blockSubtitle
          blockTitle
          blocks {
            _rawContent(resolveReferences: { maxDepth: 10 })
          }
          classes
        }
        ... on SanityImageWithLeafs {
          name
          _type
          image {
            alt
            asset {
              fluid(maxWidth: 10000) {
                ...GatsbySanityImageFluid
              }
            }
          }
          imageAlign
          hasLeafs
          backgroundColor
          title
          _rawContent(resolveReferences: { maxDepth: 10 })
          classes
        }
      }
    }
    sanityNavigation {
      content {
        title
        slug {
          current
        }
        childs {
          title
          slug {
            current
          }
        }
      }
    }
  }
`
