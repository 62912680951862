import React from "react"
import "./index.scss"
import { SanityHeroWithBlocks } from "@utils/globalTypes"
import { Row, Col } from "react-bootstrap"
import BackgroundImage from "gatsby-background-image"
import { BaseBlockContent } from "@utils/richText"
import useResizeWidth from "@hooks/useResizeWidth"
import Image from "gatsby-image"

type Props = {
  sanityHeroWithBlocks: SanityHeroWithBlocks
  classes?: string
}

const HeroWithBlocks = ({ sanityHeroWithBlocks, classes = "" }: Props) => {
  const {
    titleWithImage,
    blockTitle,
    blockSubtitle,
    blocks,
  } = sanityHeroWithBlocks
  const { width } = useResizeWidth()
  const renderContent = () => (
    <div>
      <div className="hwb-layout">
        <section id={sanityHeroWithBlocks?.name}>
          <Row className="block-grids hwb-blocks-grid wwa-container">
            <Col xs={12} className="hwb-grid-title">
              {blockTitle && <h2>{blockTitle}</h2>}
            </Col>
            <Col className="no-padding">
              {blockSubtitle && <h3>{blockSubtitle}</h3>}
            </Col>
            <Row className="grid-block-grid">
              {blocks && (
                <>
                  {blocks.map(({ _rawContent }, index) => (
                    <Col xs={12} lg={6} key={index} className="no-padding">
                      <BaseBlockContent blocks={_rawContent} key={index} />
                    </Col>
                  ))}
                </>
              )}
            </Row>
          </Row>
        </section>
      </div>
    </div>
  )

  return sanityHeroWithBlocks.backgroundImage ? (
    <BackgroundImage
      Tag={`section`}
      fluid={sanityHeroWithBlocks?.backgroundImage?.asset?.fluid}
      backgroundColor={sanityHeroWithBlocks?.backgroundColor || "transparent"}
      alt={sanityHeroWithBlocks?.backgroundImage?.alt || "birchbrook"}
      style={{
        backgroundPosition:
          width > 960 ? (width > 1100 ? "center 90%" : "center center") : "top",
        backgroundSize:
          width > 960 ? (width > 1100 ? "90%" : "100%") : "contain",
        opacity: 1,
      }}
      className={`${sanityHeroWithBlocks.classes} ${classes}`}
    >
      {renderContent()}
    </BackgroundImage>
  ) : (
    <section
      className={`${sanityHeroWithBlocks.classes} ${classes}`}
      style={
        sanityHeroWithBlocks?.backgroundColor
          ? { backgroundColor: sanityHeroWithBlocks.backgroundColor }
          : {}
      }
    >
      {renderContent()}
    </section>
  )
}

export default HeroWithBlocks
